export default `
/*
Include stylesheets from libraries here so that the reset in styles.scss doesn't stomp on them
*/
/* Flat UI */
/* Settings for input range control */
.snapgadget-btn {
  padding: 0.5em 0.4em;
  width: auto;
  border-radius: 5px;
  border: solid 0.05em hsl(0, 0%, 80%);
  text-decoration: none;
  text-align: center;
  font-size: 1em;
  color: #fff;
}
.snapgadget-btn:hover {
  text-decoration: none;
}
.snapgadget-btn-icon {
  border: none;
  background: none;
}
.snapgadget-btn-sm {
  font-size: 0.8em;
  padding: 0.3em 0.4em;
}
.snapgadget-btn-primary {
  background-color: #3667ae;
  border: solid 0.05em #3667ae;
}
.snapgadget-btn-secondary {
  background-color: transparent;
  border: solid 0.05em hsl(0, 0%, 80%);
  color: hsl(0, 0%, 40%);
}
.snapgadget-buttons {
  grid-column: 2/6;
  display: flex;
}
.snapgadget-buttons button {
  grid-column: none;
}
.snapgadget-buttons button:not(:last-child) {
  margin-right: 0.5em;
}
.snapgadget-button-group {
  grid-column: 2/6;
  display: flex;
}
.snapgadget-button-group button {
  grid-column: none;
}
.snapgadget-button-group-column {
  grid-column: 2/6;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.snapgadget-button-group-column button {
  grid-column: none;
}
.snapgadget-button-group-column button:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.snapgadget-button-group-column button:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (min-width: 768px) {
  .snapgadget-button-group button:not(:last-child) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .snapgadget-button-group button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
@media (max-width: 768px) {
  .snapgadget-form .snapgadget-buttons {
    grid-column: 1/3;
    justify-content: center;
  }
  .snapgadget-form .snapgadget-button-group {
    grid-column: 1/6;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .snapgadget-form .snapgadget-button-group button {
    grid-column: 1/6;
  }
  .snapgadget-form .snapgadget-button-group button:not(:last-child) {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .snapgadget-form .snapgadget-button-group button:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.snapgadget-form input.snapgadget-checkbox {
  /* Remove most all native input styles */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: hsl(0, 0%, 40%);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid hsl(0, 0%, 80%);
  border-radius: 0.15em;
  display: grid;
  align-content: center;
  justify-content: center;
  place-content: center;
}
.snapgadget-form input.snapgadget-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #000;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}
.snapgadget-form input[type=checkbox]:checked::before {
  transform: scale(1);
}
.snapgadget-form input[type=checkbox]:focus {
  outline: max(2px, 0.15em) solid hsl(0, 0%, 80%);
  outline-offset: max(2px, 0.15em);
}
.snapgadget-form input[type=checkbox]:disabled {
  color: #959495;
  cursor: not-allowed;
}
.snapgadget-form {
  background-color: #fff;
  color: hsl(0, 0%, 40%);
  font-size: 1em;
  font-weight: bold;
  font-family: "Lucida Grande", Tahoma, Arial, Verdana, sans-serif;
  display: grid;
  grid-template-columns: 1fr 1em 1fr 1fr 1fr;
  grid-gap: 0.8em 0.6em;
  grid-auto-flow: row;
  align-items: center;
}
.snapgadget-form label {
  text-align: right;
  grid-column: 1/2;
}
.snapgadget-form select, .snapgadget-form input[type=text], .snapgadget-form textarea {
  padding: 0.5em 0.8em;
  border: 0.05em solid hsl(0, 0%, 80%);
  border-radius: 0.25em;
}
.snapgadget-form input, .snapgadget-form textarea, .snapgadget-form select {
  color: hsl(0, 0%, 60%);
}
.snapgadget-form input, .snapgadget-form output, .snapgadget-form textarea, .snapgadget-form select, .snapgadget-form .snapgadget-form-element {
  font-size: 1em;
  grid-column: 2/6;
  width: auto;
  margin: 0;
}
.snapgadget-form button {
  grid-column: 2/4;
}
.snapgadget-form input[type=checkbox], .snapgadget-form input[type=radio] {
  grid-column: 2/3;
  justify-self: end;
  margin: 0;
}
.snapgadget-form label, .snapgadget-form input[type=checkbox] + label, .snapgadget-form input[type=radio] + label {
  width: auto;
  padding: 0;
  margin: 0;
}
.snapgadget-form input[type=checkbox] + label, .snapgadget-form input[type=radio] + label {
  grid-column: 3/6;
  text-align: left;
}
.snapgadget-form textarea + label {
  align-self: start;
}
.snapgadget-form .snapgadget-sub {
  grid-column: 5/6;
}
.snapgadget-form .snapgadget-sub-label {
  grid-column: 4/5;
}
.snapgadget-form .snapgadget-form-row, .snapgadget-form .snapgadget-form-row-flex {
  grid-column: 2/6;
}
.snapgadget-form .snapgadget-form-row-checkbox-children {
  grid-column: 3/6;
}
.snapgadget-form .snapgadget-form-row-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1em 2em;
}
.snapgadget-form .snapgadget-info-block {
  grid-column: 2/6;
  background-color: rgba(236, 240, 241, 0.2);
  border: 0.05em solid hsl(0, 0%, 80%);
  padding: 1em;
  border-radius: 0.25em;
  font-size: 0.9em;
}
.snapgadget-form .snapgadget-info-block h2 {
  font-size: 1.6em;
}
.snapgadget-form .snapgadget-info-block h3 {
  font-size: 1.4em;
}
.snapgadget-form .snapgadget-info-block h4 {
  font-size: 1.2em;
}
.snapgadget-form .snapgadget-form-part {
  display: flex;
  align-items: center;
}
.snapgadget-form .snapgadget-form-part * {
  grid-column: none;
}
.snapgadget-form .snapgadget-form-part *:not(:last-child) {
  margin-right: 1em;
}
.snapgadget-form .snapgadget-form-part label {
  margin-right: 0.5em !important;
}
.snapgadget-form-text {
  width: 100%;
}
.snapgadget-form-error {
  grid-column: 2/6;
  font-size: 0.9em;
  color: #e74c3c;
}
.snapgadget-text-muted {
  font-size: 0.8em;
  color: #bdc3c7;
}
.snapgadget-disabled {
  color: #959495;
  cursor: not-allowed;
}
@media (max-width: 768px) {
  .snapgadget-form {
    grid-template-columns: 1em 1fr;
  }
  .snapgadget-form label {
    text-align: left;
    grid-column: 1/3;
  }
  .snapgadget-form input, .snapgadget-form output, .snapgadget-form textarea, .snapgadget-form select, .snapgadget-form button, .snapgadget-form .snapgadget-form-element {
    grid-column: 1/3;
  }
  .snapgadget-form input[type=checkbox],
  .snapgadget-form input[type=radio] {
    grid-column: 1/2;
  }
  .snapgadget-form input[type=checkbox] + label,
  .snapgadget-form input[type=radio] + label {
    grid-column: 2/6;
  }
  .snapgadget-form .snapgadget-sub {
    grid-column: 2/3;
  }
  .snapgadget-form .snapgadget-sub-label {
    grid-column: 2/3;
  }
  .snapgadget-form .snapgadget-form-row, .snapgadget-form .snapgadget-form-row-flex, .snapgadget-form .snapgadget-form-row-checkbox-children {
    grid-column: 1/6;
  }
}
.snapgadget-color-picker {
  position: relative;
}
.snapgadget-color-picker .react-colorful,
.snapgadget-color-picker .react-colorful__saturation,
.snapgadget-color-picker .react-colorful__hue,
.snapgadget-color-picker .react-colorful__alpha-gradient,
.snapgadget-color-picker .react-colorful__interactive {
  margin-right: 0em !important;
}
.snapgadget-color-picker .react-colorful,
.snapgadget-color-picker .react-colorful__saturation,
.snapgadget-color-picker .react-colorful__hue,
.snapgadget-color-picker .react-colorful__alpha-gradient,
.snapgadget-color-picker .react-colorful__interactive {
  width: 100%;
}
.snapgadget-color-swatch {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-indent: -2000px;
}
.snapgadget-color-popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 100;
  background-color: #FFF;
}
.snapgadget-color-input {
  padding: 0.4em;
}
.snapgadget-slider-thumb:hover {
  box-shadow: 0 0 0 4px #eee;
}
.snapgadget-loader-container {
  display: flex;
  gap: 0.5em;
  align-items: center;
}
.snapgadget-loader {
  border: 4px solid #ecf0f1;
  border-top: 4px solid #499c85;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  display: inline-block;
  margin-right: 5px;
}
.snapgadget-loader-message {
  color: #7f8c8d;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) {
  label.snapgadget-data-loaded {
    margin-top: -1.2em;
  }
}
span.snapgadget-sub-label {
  display: block;
  font-size: 0.8em;
}
.snapgadget_results {
  azimuth: center !important;
  background-attachment: scroll !important;
  background-image: none !important;
  background-position: 0% 0% !important;
  background-repeat: repeat !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
  bottom: auto !important;
  caption-side: top !important;
  clear: none !important;
  clip: auto !important;
  content: normal !important;
  counter-increment: none !important;
  counter-reset: none !important;
  cursor: auto !important;
  direction: ltr !important;
  elevation: level !important;
  empty-cells: show !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  list-style-image: none !important;
  list-style-position: outside !important;
  list-style-type: disc !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  orphans: 2 !important;
  outline-color: invert !important;
  outline-style: none !important;
  outline-width: medium !important;
  overflow: auto !important;
  page-break-after: auto !important;
  page-break-before: auto !important;
  page-break-inside: auto !important;
  table-layout: auto !important;
  text-align: left !important;
  text-decoration: none !important;
  text-indent: 0 !important;
  text-transform: none !important;
  unicode-bidi: normal !important;
  vertical-align: baseline !important;
  visibility: visible !important;
  white-space: normal !important;
  widows: 2 !important;
  word-spacing: normal !important;
  display: block !important;
  margin: auto !important;
  padding: 5px !important;
  float: none !important;
  font-style: none !important;
}
.snapgadget_results {
  height: auto;
  max-height: none;
}
.snapgadget_results *,
.snapgadget_results *:before,
.snapgadget_results *:after {
  box-sizing: border-box;
}
.snapgadget_results * {
  azimuth: center;
  background-attachment: scroll;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  border-collapse: separate;
  border-spacing: 0;
  bottom: auto;
  caption-side: top;
  clear: none;
  clip: auto;
  color: #0f214a;
  content: normal;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: ltr;
  elevation: level;
  empty-cells: show;
  font-family: "Lucida Grande", Tahoma, Arial, Verdana, sans-serif;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: disc;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  orphans: 2;
  outline-color: invert;
  outline-style: none;
  outline-width: medium;
  overflow: visible;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: auto;
  table-layout: auto;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  top: auto;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: visible;
  white-space: normal;
  widows: 2;
  width: auto;
  word-spacing: normal;
  margin: 0px;
  padding: 0px;
  font-size: 1em;
  float: none;
  border: none;
  font-style: none;
  background: transparent;
  text-align: left;
}
.snapgadget_results .snapgadget_result {
  width: 198px;
  height: 260px;
  padding: 10px;
  float: left;
  display: inline-block;
  border-radius: 10px;
  margin: 15px;
  position: relative;
}
.snapgadget_results .snapgadget_result a {
  cursor: pointer;
  text-decoration: none;
}
.snapgadget_results .snapgadget_result a:hover {
  text-decoration: underline;
}
.snapgadget_results .snapgadget_result .snapgadget_header {
  height: 50px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
}
.snapgadget_results .snapgadget_result .snapgadget_content {
  width: 176px;
  height: 170px;
}
.snapgadget_results .snapgadget_result .snapgadget_main {
  float: left;
  width: 176px;
}
.snapgadget_results .snapgadget_result .snapgadget_main .snapgadget_image,
.snapgadget_results .snapgadget_result .snapgadget_main .snapgadget_linked_image {
  text-align: center;
}
.snapgadget_results .snapgadget_result .snapgadget_main .snapgadget_image img,
.snapgadget_results .snapgadget_result .snapgadget_main .snapgadget_linked_image img {
  max-height: 100px;
  max-width: 100%;
}
.snapgadget_results .snapgadget_result .snapgadget_close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  position: absolute;
  top: -1px;
  right: 4px;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #95a5a6;
  display: none;
}
.snapgadget_results .snapgadget_result .snapgadget_close:focus {
  outline: 0;
}
.snapgadget_results .snapgadget_result .snapgadget_meta {
  display: none;
  float: right;
}
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget_description {
  color: #95a5a6;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls {
  float: left;
  /*
  .snapgadget-linkFB {
    background: transparent url(image-path("facebook_sm.png")) no-repeat;
  }
  .snapgadget-linkTwitter {
    background: transparent url(image-path("twitter_sm.png")) no-repeat;
  }
  .snapgadget-linkPinterest {
    background: transparent url(image-path("pinterest_sm.png")) no-repeat;
  }
  */
}
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkFB,
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkTwitter,
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkPinterest {
  width: 32px;
  height: 32px;
  border: none;
  text-indent: -2000px;
}
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkFB:hover,
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkTwitter:hover,
.snapgadget_results .snapgadget_result .snapgadget_meta .snapgadget-social-controls .snapgadget-linkPinterest:hover {
  background-color: none;
  border: none;
}
.snapgadget_results .snapgadget_result .snapgadget_linked_image {
  display: none;
}
.snapgadget_results .snapgadget_result .snapgadget_view_now {
  position: absolute;
  bottom: 30px;
  display: none;
}
.snapgadget_results .snapgadget_result .snapgadget_footer {
  color: #bdc3c7;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}
.snapgadget_results .snapgadget_result .snapgadget_footer .snapgadget_merchant {
  font-size: 10px;
}
.snapgadget_results .snapgadget_result .snapgadget_footer .snapgadget_price {
  font-weight: bold;
}
.snapgadget_results .snapgadget_result .snapgadget-onsale {
  text-decoration: line-through;
}
.snapgadget_results .snapgadget_result_expanded {
  width: 425px;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_linked_image {
  display: block;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_image {
  display: none;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_close {
  display: block;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_header {
  height: 20px;
  text-align: left;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_content {
  width: 425px;
  height: 204px;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_meta {
  display: block;
  margin-right: 25px;
  width: 224px;
}
.snapgadget_results .snapgadget_result_expanded .snapgadget_footer .snapgadget_view_now {
  display: block;
  float: left;
  margin: -5px 5px 0 0;
}
.snapgadget-result a {
  cursor: pointer;
}
.snapgadget-result h4 {
  margin: 0 68px 5px 0;
  height: 60px;
  overflow: hidden;
  line-height: 14px;
}
.snapgadget-result h4 a {
  font-size: 18px;
  cursor: pointer;
}
.snapgadget-result p {
  margin: 0 0 10px;
  font-size: 12px;
}
.snapgadget-result .snapgadget-content img {
  float: left;
  height: 100px;
  width: 100px;
  margin: 0 5px 5px 0;
}
.snapgadget-result .snapgadget-content p {
  height: 120px;
  overflow: hidden;
}
.snapgadget-result .snapgadget-onsale {
  text-decoration: line-through;
}
.snapgadget-result .snapgadget-original-price {
  position: absolute;
  top: 4px;
  right: 4px;
}
.snapgadget-result .snapgadget-original-price a {
  font-size: 14px;
  font-weight: bold;
}
.snapgadget-result .snapgadget-original-price.snapgadget-onsale {
  top: 45px;
  right: 4px;
}
.snapgadget-result .snapgadget-footer {
  clear: both;
}
.snapgadget-result .snapgadget-footer .snapgadget-merchant {
  position: absolute;
  bottom: 0;
  left: 8px;
}
.snapgadget-result .snapgadget-footer .snapgadget-merchant a {
  font-size: 12px;
  color: #95a5a6;
}
.snapgadget-result .snapgadget-footer .snapgadget-buy-it {
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.snapgadget-result .snapgadget-footer .snapgadget-buy-it a {
  padding: 6px 12px;
}
.snapgadget-result-bar {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;
  border-bottom: solid 1px #bdc3c7;
  margin: 0.5em 0;
}
.snapgadget-result-bar-info {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-grow: 2;
  height: 100%;
  width: 100%;
  gap: 5px;
  align-items: center;
}
.snapgadget-result-bar-info-name {
  font-size: 1.1em;
  font-weight: bold;
  width: 100%;
  margin-left: 5px;
  text-align: center;
}
.snapgadget-result-bar-info-merchant {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.snapgadget-result-bar-select {
  padding: 5px 8px;
  background: #f0f0f0;
}
.snapgadget-result-bar-select-wrap {
  margin-top: 5px;
}
.snapgadget_controls .snapgadget_controls_row {
  display: flex;
}
.snapgadget_controls .snapgadget_controls_sub_row {
  display: flex;
  justify-content: flex-end;
}
.snapgadget_controls .snapgadget_controls_row_reverse {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.snapgadget_controls .snapgadget_on_sale_only {
  margin: 0 10px;
}
.snapgadget_controls .snapgadget_search_container,
.snapgadget_controls .snapgadget-price-slider,
.snapgadget_controls .snapgadget_refine {
  margin: 0.6em 0.3em;
}
.snapgadget_controls .snapgadget-filter-btn-container {
  margin: 0.7em 0;
}
.snapgadget_controls .snapgadget-filter-btn-container .snapgadget-btn-icon {
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.snapgadget_controls .snapgadget_result_controls_container {
  text-align: center;
  margin: 40px;
  font-size: 14px;
  clear: both;
  display: block;
}
.snapgadget_controls .snapgadget_result_controls_container p {
  text-align: center;
}
.snapgadget_controls .snapgadget_result_controls_container .snapgadget-btn {
  width: 200px;
  margin: 10px auto;
}
.snapgadget_controls .snapgadget_refine {
  flex-grow: 1;
}
.snapgadget_controls .snapgadget_refine select {
  width: 100%;
}
.snapgadget_controls .snapgadget-price-slider {
  padding: 0px 25px 10px 10px;
  flex-grow: 2;
}
.snapgadget_controls .snapgadget_search_container {
  display: flex;
  flex-grow: 1;
  height: 31px;
  gap: 2px;
}
.snapgadget_controls .snapgadget_search_container .snapgadget_query {
  border-radius: 5px;
  padding: 4px 6px 4px 6px;
  font-size: 1em;
  margin: 0px;
  flex-grow: 3;
  border: solid 1px;
}
.snapgadget_controls .snapgadget_search_container .snapgadget_search {
  flex-grow: 1;
  display: inline-block;
  min-width: 65px;
}
.snapgadget_controls .snapgadget_search_container .snapgadget-btn {
  padding: 0;
}
.snapgadget_controls .snapgadget_refine .snapgadget-sort-order {
  width: 100%;
  max-width: 100%;
  font-size: 1em;
  border-radius: 5px;
  height: 31px;
}
.snapgadget-button-text {
  display: none;
}
@media all and (max-width: 1100px) {
  .snapgadget_controls_row {
    flex-direction: column;
  }
  .snapgadget_controls_row_reverse {
    margin-bottom: 1em;
  }
  .snapgadget-filter-btn-container .snapgadget-button-text {
    display: inline;
  }
  .snapgadget_controls .snapgadget-price-slider {
    margin-bottom: 1.5em;
  }
}
.snapgadget-button-wrapper {
  display: flex;
  align-items: center;
}
.snapgadget-button-wrapper a {
  height: 18px;
}
.snapgadget-results {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.snapgadget-result-bars-container {
  margin: 5px 0;
}
.snapgadget-result-bars-scroll {
  padding: 1px 9px 1px 5px;
  overflow-y: auto;
}
.snapgadget-search-container {
  display: flex;
  flex-direction: column;
}
.snapgadget-search {
  display: flex;
  flex-direction: column;
}
.snapgadget-search .snapgadget-search-input {
  flex: 1;
  line-height: normal;
  width: 100%;
  font-size: 1em;
  padding: 0.2em;
}
.snapgadget-search .snapgadget-search-facet-contents {
  overflow-y: auto;
}
.snapgadget-search .snapbrains-facet-button {
  background: none;
  border: none;
}
.snapgadget-search .snapgadget-btn-icon {
  border: none;
  background: none;
}
.snapgadget-addon-container {
  display: flex;
  align-items: center;
}
.snapgadget-addon-field {
  flex: 1;
}
.snapgadget-search-input-container {
  display: flex;
  position: relative;
}
.snapgadget-no-more-results {
  font-weight: bold;
  font-size: 1.2em;
}
.snapgadget-search-facets, .snapgadget-search-facets-min {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  align-items: start;
  grid-gap: 0.5em 1em;
}
.snapgadget-search-facets {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b c d";
}
.snapgadget-search-facets-min {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "a b c";
}
.snapgadget-search-facet-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.snapgadget-search-facet-merchants {
  grid-area: a;
}
.snapgadget-search-facet-categories {
  grid-area: b;
}
.snapgadget-search-facet-brands {
  grid-area: c;
}
.snapgadget-search-facet-sort-sale {
  grid-area: d;
}
@media (max-width: 768px) {
  .snapgadget-search-facets {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "a b d" "c b d";
  }
}
.snapgadget-search-facet {
  width: 100%;
}
.snapgadget-search-facet h2 {
  font-size: 14px;
  margin: 0;
  border-bottom: solid 1px #ccc;
  width: 100%;
}
.snapgadget-search-facet .snapgadget-sub-head {
  margin-top: 6px;
}
.snapgadget-search-facet-contents {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.snapgadget-facet-button {
  text-align: left;
  justify-content: left;
  align-items: center;
  padding: 2px 4px;
  border: none;
  background: none;
}
.snapgadget-facet-button svg {
  margin-right: 4px;
  outline: none;
}
.snapgadget-facet-button:hover {
  background: #eee;
}
button.snapgadget-facet-button {
  display: flex;
}
.snapgadget-onsale-label {
  font-size: 0.9em;
}
.snapgadget-clear-filter-container {
  margin-top: 1em;
}
.snapgadget-clear-filter {
  margin: 0 0.3em;
  display: flex;
  justify-content: center;
}
.snapgadget-clear-filter button {
  font-size: 0.8em;
}
.snapgadget-rotate-enter {
  opacity: 0;
}
.snapgadget-rotate-enter-active {
  opacity: 1;
  transform: rotate(90deg);
  transition: all 100ms ease-in-out;
}
.snapgadget-rotate-enter-done {
  opacity: 1;
}
.snapgadget-rotate-exit {
  opacity: 1;
}
.snapgadget-rotate-exit-active {
  opacity: 0;
  transform: rotate(90deg);
  transition: all 100ms ease-in-out;
}
.snapgadget-rotate-exit-done {
  opacity: 0;
  display: none;
}
.snapgadget-slide-enter {
  transform: scale(1, 0);
}
.snapgadget-slide-enter-active {
  transform: scale(1, 1);
  transform-origin: top;
  transition: all 100ms ease-in-out;
}
.snapgadget-slide-exit {
  transform: scale(1, 1);
}
.snapgadget-slide-exit-active {
  transform: scale(1, 0);
  transform-origin: top;
  transition: all 100ms ease-in-out;
}
.snapgadget-slide-exit-done {
  display: none;
}
.snapgadget-fade-enter {
  opacity: 0;
}
.snapgadget-fade-enter-active {
  opacity: 1;
  transition: all 100ms ease-in-out;
}
.snapgadget-fade-enter-done {
  opacity: 1;
}
.snapgadget-fade-exit {
  opacity: 1;
}
.snapgadget-fade-exit-active {
  opacity: 0;
  transition: all 100ms ease-in-out;
}
.snapgadget-fade-exit-done {
  opacity: 0;
  display: none;
}
.snapgadget-filter-error {
  color: #FF0000;
  padding-top: 12px;
}
.snapgadget-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}
.snapgadget-btn-icon {
  vertical-align: middle;
}
.snapgadget-btn-icon :focus {
  outline: none;
}
.snapgadget-center {
  display: flex;
  justify-content: center;
}
@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}
.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}
.react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
/*# sourceMappingURL=to.css.map */`;
