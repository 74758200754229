import { createRoot } from 'react-dom/client';

import { SNAPDEALIST_APP_ID } from '@snapbrains/snapav/src/avantlink';
import { configureSettings, searchSettings } from '@snapbrains/snaparts/src/utils/settings_builder';
import { SearchContextProvider } from '@snapbrains/snaparts/src/hooks/use_search_state';
import ready from '@snapbrains/snaputils/src/ready';

import { AppName } from '../constants';
import styles from './styles/styles.scss';
import includes from './styles/includes.css';
import Home from './components/home.jsx';

function Root(props) {
  const settings = {
    ...props.settings,
    appId: SNAPDEALIST_APP_ID,
  };
  return (
    <SearchContextProvider settings={searchSettings(props.settings)}>
      <Home settings={settings} />
    </SearchContextProvider>
  );
}

function addStyles(stylesString) {
  // Load the styles
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(stylesString));
  document.head.appendChild(style);
}

window[AppName] = {
  run(settings) {
    addStyles(styles);
    addStyles(includes);
    const el = document.getElementById(settings.element);
    // Make sure DOM element is empty
    while (el.firstChild) {
      el.removeChild(el.firstChild);
    }

    const root = createRoot(el); // createRoot(container!) if you use TypeScript
    root.render(<Root settings={configureSettings(settings)} />);
  },

  init(settings) {
    ready(() => window[AppName].run(settings));
  }
};
